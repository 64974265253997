import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";

import {TenantFrontendApi_RemoveSuperTenantFromTenant, TenantFrontendApi_AddTenantToSuperTenant, TenantFrontendApi_GetTenant, TenantFrontendApi_GetTenantList} from "./TenantFrontendApi";
import { Translate } from "./Translator"
import Header from "./Header";
import MainMenu from "./MainMenu";
import Footer from "./Footer"
import BlueBar from "./BlueBar";

import HomeLogo from "./Assets/HomeLogo.png"

import './EditUser.css';
import './EditTenant.css';
import './Licences.css';
import './Tenant.css';
import './BuyLicence.css';
import './SuperTenant.css';

function SuperTenant(){

    const navigate = useNavigate()
    const [mRepaint,SetRepaint] = useState()

    const [mTenantGuid,SetTenantGuid] = useState("")
    const [mTenant,SetTenant] = useState("")
    const [mTenantList,SetTenantList] = useState([])

    useEffect(() => {

        async function GetTenantList() {
            try
            {
                const TenantListResponse = await TenantFrontendApi_GetTenantList(sessionStorage.getItem("token"))
                SetTenantList(TenantListResponse.data)
                RemoveSuperTenants(mTenantGuid)
            }
            catch (ex)
            {
                var error = ex;
                try { error = ex.response.data.Exception; } catch {}  
                console.log("Error: " + error);
            }
        }

        async function GetTenant(aTenantGuid) {
            try
            {   
                console.log("Getting Tenant")
                const TenantResponse = await TenantFrontendApi_GetTenant(sessionStorage.getItem("token"),aTenantGuid)
                SetTenant(TenantResponse.data)
    
            }
            catch (ex)
            {
                var error = ex;
                try { error = ex.response.data.Exception; } catch {}  
                console.log("Error: " + error);
            }
        }

        //Get the parameters from the URL
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        const ciParams = new URLSearchParams();
        for (const [name, value] of params) { ciParams.append(name.toLowerCase(), value); }
        const tenantguid = ciParams.get('tenantguid')
        SetTenantGuid(tenantguid)

        GetTenant(tenantguid)
        GetTenantList()
        
    }, [mTenantGuid]);

    function languageChanged(){
        SetRepaint(!mRepaint)
    }

    function AddingThisTenants() {
        
        mTenantList.forEach(tenant => {
            if (tenant.SuperTenantGuid === mTenantGuid) 
            {
                console.log("SelectedTenant: " + tenant.Company);
                AddTenantToTheSuperTenant(tenant.TenantGuid);
            }
            else
            {
                RemoveSuperTenantFromTenant(tenant.TenantGuid);
            }
        });
    
        navigate(`../tenant?tenantguid=${mTenantGuid}`);
    }

    async function RemoveSuperTenantFromTenant(aTenantGuid) {
        try
        {  console.log("TenantGuid=" + aTenantGuid)
            const TenantResponse = await TenantFrontendApi_RemoveSuperTenantFromTenant(sessionStorage.getItem("token"),aTenantGuid)
            console.log(TenantResponse.data)

        }
        catch (ex)
        {
            var error = ex;
            try { error = ex.response.data.Exception; } catch {console.log("Error: " + error.response.data.Exception);}  
            
        }
    }

    async function AddTenantToTheSuperTenant(aTenantGuid) {
        try
        {  
            const TenantResponse = await TenantFrontendApi_AddTenantToSuperTenant(sessionStorage.getItem("token"),aTenantGuid,mTenantGuid)
            console.log(TenantResponse.data)

        }
        catch (ex)
        {
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
        }
    }

    const RemoveSuperTenants = (aSuperTenantGuid) =>
    {
        SetTenantList((mTenantList) => mTenantList.filter(tenant => tenant.TenantGuid !== aSuperTenantGuid));
        SetTenantList((mTenantList) => mTenantList.filter(tenant => tenant.IsSuperTenant !== true));
        SetTenantList((mTenantList) => mTenantList.filter(tenant => tenant.SuperTenantGuid === aSuperTenantGuid || tenant.SuperTenantGuid === ""))
    };

    function SetThisTenantAsTenantOfSuperTenant(aCheckValue, aTenant) {

        aTenant.SuperTenantGuid = aCheckValue ? mTenantGuid : "";

        const NewTenantList = mTenantList.map(tenant =>
            tenant.TenantGuid === aTenant.TenantGuid ? aTenant : tenant
        );
    
        SetTenantList(NewTenantList);
    }
    
    let PageLocation = <span/>
    PageLocation = <tr height="60">
                        <td width="40" align="left">
                            <span width="20" align="left"><img alt="Home logo" className="HomeLogo pointer" src={HomeLogo} onClick={() => navigate('/')}/></span>              
                            <span className="underline pointer bold" width="40" align="left" text-align="button" onClick={() => navigate('../reseller')}>{Translate("Tenants")}</span>
                            <span width="20" className="blue" align="center"> / </span>
                            <span className="underline pointer bold" width="20" align="left" onClick={() => navigate('../tenant?tenantguid=' + mTenantGuid)}>{mTenant.Company}</span>
                            <span width="20" className="blue" align="center"> / </span>
                            <span className="bold" width="40" align="left" text-align="button" >{Translate("Reseller tenants")}</span> 
                        </td>
                    </tr>

    return(
        <span>
            
            <Header onLanguageChanged={() => languageChanged()}/>

            <MainMenu MainMenuNumber={1} />

            <table width="1200" cellPadding={0} cellSpacing={0} align="center">
                <tbody>
                    <tr className="pagetop3">
                        <td height="75" valign="bottom">
                            <table width="100%" cellPadding={0} cellSpacing={0} className="down10px">
                                <tbody>
                                    {PageLocation}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table width="1200" cellPadding={0} cellSpacing={0} align="center" >
                <tbody>                

                    <tr className="pagetop4">
                        <td colSpan={4} height="75" valign="left" className="activetenants width30">
                            {Translate("Managing tenants of this reseller")}
                        </td>
                    </tr>

                    <tr className="pagetop4">
                        <td colSpan={4} className="horizontalLine width30" >
                            <BlueBar/>
                        </td>
                    </tr>

                    <tr height="50"/>
                    

                </tbody>
            </table>

            {/*Tenants table*/}
            <table className="width1200" align="center">
              <tbody>
                <tr>
                    <td colSpan={2} width="500"></td>
                    <td align="right"><button
                        className="blackbackground white pointer borderradios buylicencebutton btn btn-dark" 
                        onClick={()=>AddingThisTenants()}>{Translate("Applying changes")}</button>
                    </td>
                </tr>

                <tr height="10"/>

                <tr className="tenanttableheader">

                  {/* Other columns */}
                  <td colSpan={2} className="bold bordertop borderleft bluebackground white">
                    {Translate("Name")}
                  </td>
                  <td colSpan={2} className="bold white bordertop borderleft bluebackground widthusertenantstable white borderright">
                    {Translate("Added to this super tenant")}
                  </td>
                </tr>

                {/* Tenant list rows */}
                {mTenantList && mTenantList.length > 0 ? (mTenantList.sort((a, b) => a.Company.localeCompare(b.Company)).map((tenant, index) => (
                      <tr className="contentenatable" key={index}>
                        
                        {/* Tenant Name */}
                        <td
                          colSpan={2}
                          className="tenantsname borderbottomright bold leftpadding10"
                        >
                          {tenant.Company}
                        </td>

                        {/* Super tenant checkbox */}
                        <td className="borderbottomright">
                            <table>
                                <tbody>
                                    <tr>
                                        <td width="15"/>
                                        <td><input type="checkbox"
                                         onChange={e=>SetThisTenantAsTenantOfSuperTenant(e.target.checked,tenant)}
                                         checked = {tenant.SuperTenantGuid ? (tenant.SuperTenantGuid === mTenantGuid) : false } /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>

                      </tr>
                    ))
                ) : (
                  <tr>
                    <td
                      className="borderleft borderbottom borderright bold"
                      align="center"
                      colSpan={6}
                    >
                      {Translate("No tenant found")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
                        
            <table>
                <tbody>
                    <tr height="60"></tr>
                </tbody>
            </table>

            <Footer/>

        </span>
    )
}

export default SuperTenant