import React,{useEffect,useState} from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

import {TenantFrontendApi_GetResellerUsers, TenantFrontendApi_BlockUser,TenantFrontendApi_DeleteUser} from "./TenantFrontendApi"
import { Translate } from "./Translator"
import Header from "./Header";
import MainMenu from "./MainMenu";
import BlueBar from "./BlueBar"
import Footer from "./Footer"

import HomeLogo from "./Assets/HomeLogo.png"
import SearchButton from "./Assets/SearchButton.png"
import EditLogo from "./Assets/EditLogo.png"
import Trash from "./Assets/Trash.png"
import Block from "./Assets/Block.png"

import './Users.css';

function Users(){
    
    const navigate = useNavigate();
    const [mUserList,SetUserList]=useState([])
    const [mRepaint,SetRepaint] = useState()
    const [mSearchValue, SetSearchValue] = useState("")
    const [visibleUsers, setVisibleUsers] = useState(10);
    const [mDeleteUser,SetDeleteUser] = useState("") 

    useEffect(() => {

        async function GetUsers()
        { 
            try
            {
                const UsersResponse = await TenantFrontendApi_GetResellerUsers(sessionStorage.getItem("token"))
                SetUserList(UsersResponse.data)
            }
            
            catch (ex)
            {
                var error = ex;
                try { error = ex.response.data.Exception; } catch {}  
                console.log("Error: " + error);
            }
        }

        GetUsers()

    }, []);

    function languageChanged(){
        SetRepaint(!mRepaint)
    }

    function HomePage(){
        navigate('../reseller')
    }

    let filteredUserList = []
    const lowerSearchValue = mSearchValue.toLowerCase()

    if (lowerSearchValue === "") 
    {
        filteredUserList = mUserList
    } 
    else 
    {
        filteredUserList = mUserList.filter(
            user => user.Name.toLowerCase().includes(lowerSearchValue)
        )
    }

    useEffect(() => {
        function handleScroll()
        {
            if
            (
                window.innerHeight + window.scrollY >=
                document.body.offsetHeight - 500 // Load more users when the user is 500px above the bottom
            ) 
            {
            // Increase the number of visible users
            setVisibleUsers((prevVisibleUsers) => prevVisibleUsers + 10);
            
            }
        }

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    console.log(visibleUsers)
    
    async function BlockUser(aUser)
    {
        try
        {
            console.log("Blocking User")
            console.log(aUser.TenantGuid)
            console.log(aUser)
            const BlockUserResponse = await TenantFrontendApi_BlockUser(sessionStorage.getItem("token"),aUser.UserGuid,aUser.TenantGuid,aUser.IsActive)
            console.log(BlockUserResponse.data)
             
            console.log("Getting users")
            const UsersResponse = await TenantFrontendApi_GetResellerUsers(sessionStorage.getItem("token"))
            SetUserList(UsersResponse.data)
        }

        catch(ex){
            console.log(ex.response.data)
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
        }
    }

    async function DeleteUser()
    {
        try
        {
            console.log("Deleting User")
            const DeleteUserResponse = await TenantFrontendApi_DeleteUser(sessionStorage.getItem("token"),mDeleteUser.UserGuid)
            console.log(DeleteUserResponse)
            SetDeleteUser("")

            console.log("Getting users")
            const UsersResponse = await TenantFrontendApi_GetResellerUsers(sessionStorage.getItem("token"))
            SetUserList(UsersResponse.data)   
        }

        catch(ex){
            console.log(ex.response.data)
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
            SetDeleteUser("")
        }
    }

    const HandleKeyPress = (e) => {
        if (e.key === "Enter" && filteredUserList.length === 1) {
          const user = filteredUserList[0];
          navigate(`../user?userguid=${user.UserGuid}`);
          }
    };

    return(
        <span>

            <Header onLanguageChanged={() => languageChanged()}/>

            <MainMenu MainMenuNumber={2} />
            
            <table width="1200" cellPadding={0} cellSpacing={0} align="center">
                <tbody>                
                    <tr className="pagetop3">
                        <td colSpan={7} height="75" valign="bottom">
                            <table width="100%" cellPadding={0} cellSpacing={0} className="down10px" >
                                <tbody>
                                    <tr height="60">
                                        <td width="80" align="left">
                                            <img className="HomeLogo pointer" alt="Home logo" src={HomeLogo} onClick={()=>HomePage()}></img>
                                            <span className="bold" align="left">{Translate("Users")}</span>
                                        </td>                                                                                
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table className="width1200" align="center">
                <tbody>

                    <tr height="75">
                        <td className="bold fontsize30 width30" colSpan={7}>{Translate("Reseller users")}</td>
                    </tr>

                    <tr className="pagetop4">
                        <td colSpan={7} className="horizontalLine width30" >
                            <BlueBar/>
                        </td>
                    </tr>

                    <tr height="50"/>

                    <tr className="pagetop6">
                        <td className="width30" colSpan={7} height="50" valign="left">
                            <input type="text" placeholder={Translate("Search")} onChange={e=>SetSearchValue(e.target.value)} className="searchtenants" onKeyDown={HandleKeyPress}/><img alt="Search" className="searchimage" src={SearchButton} />
                        </td>                
                    </tr>
                    
                </tbody>
            </table>


            <table className="width1200" cellPadding={0} cellSpacing={0} align="center" border="1">
                <tbody>
                    <tr>
                        <td className="tenantusertable tenantusertableheadername borderleft" >{Translate("Name")}</td>
                        <td className="tenantusertable tenantusertableheadername" >{Translate("E-mail / Sign-in")}</td>
                        <td className="tenantusertable tenantusertableheadername" >{Translate("Tenant name")}</td>
                        <td className="tenantusertable tenantusertableheadername " >{Translate("Assigned license")}</td>
                        <td colSpan={3} className="tenantusertable ">{Translate("Change")}</td>
                    </tr>

                    {filteredUserList.length > 0 ? (
                    filteredUserList.sort((a,b) => a.Name.localeCompare(b.Name)).map((user, index) => (
                    <tr height="60" key={index} className={user.IsActive ? "" : "lightfontcolor"}>
                        <td className="tenantusertablecontent borderbottom bold underline pointer" onClick={()=>navigate('../user?userguid=' + user.UserGuid)}>{user.Name}</td>
                        <td className="tenantusertablecontent borderbottom">{user.Email}</td>
                        <td className="tenantusertablecontent borderbottom underline bold pointer" onClick={()=> navigate('../tenant?tenantguid=' + user.TenantGuid)}>{user.TenantCompany}</td>
                        <td className="tenantusertablecontent underlinetext borderbottom">{user.LicenceName}</td>
                        <td className=" borderbottom">
                            <table width="100%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <table width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <img align="right" alt="edit" src={EditLogo}></img>
                                                        </td>
                                                        <td>
                                                            <td className=" underlinetext tenantusertableedit pointer" onClick={() => navigate('../edituser?userguid=' + user.UserGuid )}>{Translate("Edit")}</td>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                            
                                        </td>
                                        
                                        <td><img className="trashwidth" align="right" alt="DeleteLogo" src={Trash}></img></td>
                                        <td align="left" valign="center" className=" underlinetext tenantusertableedit pointer" onClick={() => SetDeleteUser(user) }>{Translate("Delete")}</td>
                                        <td><img className="trashwidth" align="right" alt="BlockLogo" src={Block}></img></td>
                                        <td width="*" align="left" valign="center" className = {user.IsActive ? "underlinetext tenantusertableedit pointer" : "underlinetext tenantusertableedit pointer red" } 
                                            onClick={() => BlockUser(user)}>
                                            {user.IsActive ? Translate("Block sign-in") : Translate("Unblock sign-in") }   
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    ))
                    ) : (
                    <tr>
                        <td className="borderleft borderbottom  bold" align="center" colSpan={7}>{Translate("No user found")}</td>
                    </tr>
                    )}            
                </tbody>
            </table>

            {/*Footer part*/}
            <table>
                <tbody>
                    <tr height="60"></tr>
                </tbody>
            </table>
            
            {/*Animation for deleting a user*/}
            <Modal animation={false} style={{opacity:1}} show={mDeleteUser !== ""} onHide={() => {SetDeleteUser("")}} centered={true}>
                <Modal.Header closeButton={true}>
                    <Modal.Title align="center"><span align="center">{("Are you sure you want to delete this user")}</span></Modal.Title>
                </Modal.Header>

                <Modal.Footer align="center">
                    <Button variant="dark" onClick={() => {DeleteUser()}}>{Translate("Delete")}</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="dark" onClick={() => {SetDeleteUser("")}}>Cancel</Button>
                </Modal.Footer>
                
            </Modal>

            <Footer/>
              
        </span>
    )
}

export default Users